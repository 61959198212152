<template>
  <v-container fluid grid-list-lg>
    <ReceivePayment :CustomerId="selectedInvoice ? selectedInvoice.CustomerId : null"
      :createDialog="receivePaymentDialog" @close="receivePaymentDialog = false" @submit="getList()"
      :invoice="selectedInvoice" :invoiceReceivePayment="true"></ReceivePayment>
    <v-card :loading="loading" outlined>
      <v-toolbar dense flat :color="$style.purchase.titlebar">
        <v-app-bar-nav-icon v-if="!CustomerId" @click="$router.go(-1)">
          <v-icon>mdi-chevron-left</v-icon>
        </v-app-bar-nav-icon>

        <v-toolbar-title> Invoice </v-toolbar-title>
        <v-spacer> </v-spacer>
        <v-row justify="end">
          <v-col class="shrink">
            <v-btn @click="add" outlined rounded v-if="checkRightStatus(6)" raised small>
              <v-icon small>add</v-icon> New
            </v-btn>
          </v-col>
          <v-col class="shrink" v-if="InvoiceDetails.length !== 0">
            <v-btn @click="openRunning" outlined class="ml-2" rounded v-if="checkRightStatus(6)" raised small>
              Running Invoice
            </v-btn>
          </v-col>
        </v-row>
      </v-toolbar>

      <v-toolbar dense elevation="0">
        <v-text-field v-model="search" outlined prepend-inner-icon="search" autofocus dense label="Search" single-line
          style="min-width: 250px;" hide-details clearable></v-text-field>
        <v-divider vertical class="mx-1"></v-divider>
        <v-btn-toggle v-model="invoiceStatus" @change="getList()" small group>
          <v-btn value="all">
            <span>All</span>


          </v-btn>

          <v-btn value="open">
            <span>Open</span>


          </v-btn>

          <v-btn value="paid">
            <span>Paid</span>


          </v-btn>

        </v-btn-toggle>
        <v-spacer></v-spacer>

        <!-- <DateTimePicker :defaultRange="currentBusiness?.metadata?.invoiceViewLimit?.range || 'since15DaysAgo'"
          :range="true" :hideTime="true" v-model="dateTime" @submit="getList()" /> -->
          <!-- hasInvoiceViewLimit: {{ hasInvoiceViewLimit }} -->
        <DateTimePicker :defaultRange="defaultRange" :range="true" :hideTime="true"
          v-model="dateTime" @submit="getList()" />

        <!-- <DateTimePicker :defaultRange="currentBusiness?.metadata?.invoiceViewLimit?.range || 'since15DaysAgo'"
          :range="true" :hideTime="true" v-model="dateTime" @submit="getList()" /> -->

      </v-toolbar>


      <v-divider></v-divider>


      <!-- <v-card-text class="mb-0 pb-0" v-else>
        <v-row>
          <v-col cols="12" md="3" sm="12">
            <v-text-field v-model="search" outlined prepend-inner-icon="search" rounded dense label="Search" single-line
              hide-details clearable>
            </v-text-field>
          </v-col>
          <v-spacer> </v-spacer>
          <v-col cols="12" md="4" sm="12" v-if="!showAll">
            <DateTimePicker
              :defaultRange="currentBusiness.metadata.invoiceViewLimit ? currentBusiness.metadata.invoiceViewLimit.range : 'since60DaysAgo'"
              :range="true" :hideTime="true" v-model="dateTime" @submit="getList()" />
          </v-col>
        </v-row>
      </v-card-text> -->




      <v-card-text>
        <v-data-table :headers="headers" :items="Invoices" @current-items="updateTotal" :search="search"
          :sort-desc="sortDesc" :loading="loading" :sort-by="sortBy" :page.sync="page" :items-per-page="itemsPerPage">
          <template v-slot:item="{ item }">
            <tr class="pointer">
              <td class="text-left" @click="viewInvoice(item)">
                {{ item.date | moment("L LT") }}
              </td>
              <td class="text-left" @click="viewInvoice(item)">
                <span class="pr-1">{{ item.localId }}</span>
                <v-chip color="red lighten-3" label small v-if="item.due === 0" text-color="red darken-4">Paid</v-chip>
              </td>
              <td class="text-left" v-if="!CustomerId" @click="viewInvoice(item)">
                <div>{{ item.Customer.name }}</div>
                <div v-if="currentBusiness && currentBusiness.metadata && currentBusiness.metadata.showDueDateOnInvoice"
                  class="caption">Due: <span :class="dueDateColor(item.dueDate)">{{ item.dueDate | moment("from", "now")
                    }}</span></div>


              </td>
              <!-- <td class="text-left" @click="viewInvoice(item)">
                
              </td> -->
              <td class="text-right" @click="viewInvoice(item)">
                {{ item.total | currency }}
              </td>
              <td class="text-right" @click="viewInvoice(item)">
                {{ item.payment | currency }}
              </td>
              <td class="text-right" @click="viewInvoice(item)">
                {{ item.due | currency }}
              </td>
              <td class="justify-end align-center text-right">
                <v-menu bottom left offset-y>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" icon>
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>
                  <v-list class="menu-button">
                    <v-list-item @click="viewInvoice(item)">
                      <v-list-item-title>View Invoice</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="viewPackingSlip(item)">
                      <v-list-item-title>View Packing Slip</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="item.due !== 0" @click="receivePayment(item)">
                      <v-list-item-title>Receive Payment</v-list-item-title>
                    </v-list-item>
                    <v-divider v-if="!CustomerId"></v-divider>
                    <v-list-item v-if="!CustomerId" @click="viewProfile(item)">
                      <v-list-item-title>Profile</v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                    <!-- v-if="item.due !== 0" -->
                    <v-list-item @click="editInvoice(item)">
                      <v-list-item-title>Edit</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="deleteInvoice(item)">
                      <v-list-item-title>Delete</v-list-item-title>
                    </v-list-item>
                    <ShareLink :id="item.id" :postfix="`view?key=${currentBusiness.code}`" type="invoice"
                      :text="`Invoice from ${currentBusiness.name} click the link to view`" />
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>
          <template v-slot:body.append="{ headers }" v-if="Invoices.length > 0">
            <tr :style="{ 'background-color': $style.listTotal.filter }">
              <td :colspan="headers.length - 4" class="text-right font-weight-bold">
                List Total
              </td>
              <td class="text-right font-weight-bold">
                {{ total | currency }}
              </td>
              <td class="text-right font-weight-bold">
                {{ payment | currency }}
              </td>
              <td class="text-right font-weight-bold">
                {{ balance | currency }}
              </td>
              <td></td>
            </tr>
            <tr :style="{ 'background-color': $style.listTotal.result }">
              <td :colspan="headers.length - 4" class="text-right font-weight-bold">
                Result Total
              </td>
              <td class="text-right font-weight-bold">
                {{ fullTotal | currency }}
              </td>
              <td class="text-right font-weight-bold">
                {{ fullPayment | currency }}
              </td>
              <td class="text-right font-weight-bold">
                {{ fullBalance | currency }}
              </td>
              <td></td>
            </tr>
          </template>

          <v-alert slot="no-results" :value="true" color="error" icon="warning">Your search for "{{ search }}" found no
            results.</v-alert>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="loadingDialog" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-container>
          <div class="mb-3">Please stand by deleting Invoice</div>
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import invoiceService from "../service";
import ShareLink from "@/components/ShareLink";
const DateTimePicker = () => import("@/components/DateTimePicker");
import Hashids from "hashids";
const hashids = new Hashids();

const ReceivePayment = () => import("@/modules/Customer/Payment/components/Create.vue");

export default {
  props: {
    CustomerId: {
      default: null,
    },
    showAll: {
      default: false,
    },
    due: {
      default: false,
    },
  },
  name: "invoice-list",
  data() {
    return {
      invoiceStatus: 'all',
      loadingDialog: false,
      fullTotal: 0,
      fullPayment: 0,
      fullBalance: 0,
      total: 0,
      balance: 0,
      fab: false,
      dateTime: {
        start: this.$moment()
          .startOf("days")
          .subtract(60, "days")
          .toISOString(),
        end: this.$moment().endOf("day").toISOString(),
      },
      createDialog: false,
      selectedPayee: null,
      headers: [],
      Invoices: [],
      search: null,
      loading: true,
      page: 1,
      sortBy: "localId",
      pageCount: 0,
      sortDesc: true,
      itemsPerPage: 10,
      receivePaymentDialog: false,
      selectedInvoice: null
    };
  },
  watch: {
    // invoiceStatus() {
    //   this.getList();
    // },
    CustomerId: {
      immediate: true,
      handler() {
        this.getList();
      },
    },
  },
  created() {
    this.loadHeader();
    this.$events.listen("invoiceListEvent", () => this.getList());
    // document.title = "smplsale - Invoice List";
  },
  beforeDestroy() {
    this.$events.remove("invoiceListEvent");
  },
  components: {
    DateTimePicker,
    ShareLink,
    ReceivePayment
  },
  computed: {
    ...mapGetters("global", [
      "currentUser",
      "checkRightStatus",
      "currentBusiness",
    ]),
    ...mapGetters("invoice", ["InvoiceDetails", "getEditInvoiceId"]),
    hasInvoiceViewLimit() {
      if (this.currentBusiness.metadata) {
        return this.currentBusiness && this.currentBusiness.metadata && this.currentBusiness.metadata.invoiceViewLimit;
      } else {
        return true
      }

    },
    defaultRange() {
      if (this.currentBusiness.metadata && this.currentBusiness.metadata.invoiceViewLimit) {
        return this.currentBusiness.metadata.invoiceViewLimit.range
        // return this.hasInvoiceViewLimit ? this.currentBusiness.metadata.invoiceViewLimit.range : 'since15DaysAgo';
      } else {
        return 'since15DaysAgo'
      }
      
    },
  },
  methods: {
    ...mapActions("invoice", ["ResetInvoice"]),
    dueDateColor(val) {
      const dueDate = val ? this.$moment(val) : this.$moment();
      const today = this.$moment().startOf('day');

      return dueDate.isBefore(today) ? 'red--text font-weight-bold' : 'black--text'; // Adjust color classes as needed
    },
    updateTotal(val) {
      this.total = 0;
      this.payment = 0;
      this.balance = 0;
      val.map((row) => {
        this.total += row.total;
        this.payment += row.payment;
        this.balance += row.due;
      });
    },
    receivePayment(row) {
      this.receivePaymentDialog = !this.receivePaymentDialog
      this.selectedInvoice = row
    },
    openRunning() {
      if (this.getEditInvoiceId) {
        this.$router.push({ path: `/invoice/${this.getEditInvoiceId}/edit` });
      } else {
        this.$router.push({ path: `/invoice/create` });
      }
    },
    viewOpenInvoice() {
      const hashId = hashids.encode(this.CustomerId);
      const routeData = this.$router.resolve({
        path: `/global/invoice/${hashId}/openInvoice`,
      });
      window.open(routeData.href, "newwindow", "width=850, height=1100");
    },
    add() {
      if (this.InvoiceDetails.length !== 0) {
        this.$swal({
          title: "Are you sure?",
          text: "There is an running invoice you want to clear it",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "No",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.value) {
            this.ResetInvoice();
            if (this.CustomerId) {
              this.$router.push({
                path: `/invoice/create?CustomerId=${this.CustomerId}`,
              });
            } else {
              this.$router.push({ path: `/invoice/create` });
            }
          }
        });
      } else {
        if (this.CustomerId) {
          this.$router.push({
            path: `/invoice/create?CustomerId=${this.CustomerId}`,
          });
        } else {
          this.$router.push({ path: `/invoice/create` });
        }
      }
    },
    viewProfile(val) {
      this.$router.push({ path: `/customer/${val.CustomerId}/profile` });
    },
    viewInvoice(invoice) {
      const hashId = hashids.encode(invoice.id);
      let createdLink = `/global/invoice/${hashId}/view`
      console.log('createdLink', createdLink)
      const routeData = this.$router.resolve({
        path: createdLink,
      });
      window.open(routeData.href, "newwindow", "width=850, height=1100");
    },
    viewPackingSlip(invoice) {
      const hashId = hashids.encode(invoice.id);
      const routeData = this.$router.resolve({
        path: `/global/invoice/${hashId}/packingSlip`,
      });
      window.open(routeData.href, "newwindow", "width=850, height=1100");
    },
    loadHeader() {
      this.headers = [];
      this.headers.push({
        text: this.$t("labels.date"),
        align: "left",
        value: "date",
        sortable: true,
      });

      this.headers.push({
        text: "Invoice Number",
        align: "left",
        value: "localId",
        sortable: true,
      });

      if (!this.CustomerId) {
        this.headers.push({
          text: "Customer Name",
          value: "Customer.name",
          align: "left",
          sortable: true,
        });
      }

      // this.headers.push({
      //   text: "Due Date",
      //   value: "dueDate",
      //   align: "left",
      //   sortable: true,
      // });

      // this.headers.push({
      //   text: "Note",
      //   align: "left",
      //   value: "note",
      //   sortable: true
      // });

      this.headers.push({
        text: "Total",
        value: "total",
        align: "right",
        sortable: true,
      });

      this.headers.push({
        text: "Payment",
        value: "payment",
        align: "right",
        sortable: true,
      });

      this.headers.push({
        text: "Balance",
        value: "due",
        align: "right",
        sortable: true,
      });
      this.headers.push({
        text: "",
        value: "action",
        sortable: false,
      });
    },
    getList() {
      this.loading = true;
      let where = {};
      if (!this.showAll) {
        where = {
          start: this.dateTime.start,
          end: this.dateTime.end,
        };
      }

      if (this.invoiceStatus === 'open') {
        where.due = true
      }

      if (this.invoiceStatus === 'paid') {
        where.paid = true
      }

      if (this.invoiceStatus === 'all') {
        delete where.paid
        delete where.due
      }

      if (!this.currentUser.isAdmin && this.checkRightStatus(50)) {
        where.assingedCustomer = this.currentUser.id
      }

      if (this.CustomerId) {
        where.CustomerId = this.CustomerId;
      }

      if (this.due) {
        where.due = true;
      }


      return invoiceService.getAll(where).then((response) => {
        console.log("invoice", response);
        this.loading = false;
        this.Invoices = response.data;
        this.fullTotal = 0;
        this.fullPayment = 0;
        this.fullBalance = 0;
        this.Invoices.map((row) => {
          this.fullTotal += row.total;
          this.fullPayment += row.payment;
          this.fullBalance += row.due;
        });
        return response;
      });
    },
    editInvoice(invoice) {
      // console.log('ddf', invoice)
      this.$router.push({ path: `/invoice/${invoice.id}/edit` });
    },
    deleteInvoice(invoice) {
      if (this.currentBusiness.metadata && this.currentBusiness.metadata.deletePrecaution) {
        this.$swal.fire({
          title: "Are you sure?",
          html: `You won't be able to revert this.</br> <div style="color:red;">Type <span style="font-weight: bold">delete</span> in order remove this</div>`,
          icon: "question",
          input: 'text',
          inputAttributes: {
            autocapitalize: 'off'
          },
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Delete",
        }).then((result) => {
          if (result.value && result.value === 'delete') {
            this.loadingDialog = true;
            invoiceService
              .delete(invoice.id)
              .then((response) => {
                // console.log("response delete", response);
                this.loadingDialog = false;
                if (response.data) {
                  this.$swal
                    .mixin({
                      toast: true,
                      position: "top-end",
                      showConfirmButton: false,
                      timer: 3000,
                    })
                    .fire({
                      icon: "success",
                      title: "Invoice has been deleted.",
                    });
                  // this.$swal("Deleted!", "Invoice has been deleted.", "success");
                  this.getList();
                  this.$events.emit("customerProfileEvent");
                  this.$events.emit("CustomerMiniList");
                }
              })
              .catch((error) => {
                this.loadingDialog = false;
                this.$swal({
                  title: "Delete Invoice",
                  text: error.data.message,
                  type: "error",
                });
              });
          } else if (result.dismiss === 'cancel') {

          } else {
            this.$swal("Delete", 'Type "delete" to delete item', "error");
          }
        })
      } else {
        this.$swal({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "No",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.value) {
            this.loadingDialog = true;
            invoiceService
              .delete(invoice.id)
              .then((response) => {
                // console.log("response delete", response);
                this.loadingDialog = false;
                if (response.data) {
                  this.$swal
                    .mixin({
                      toast: true,
                      position: "top-end",
                      showConfirmButton: false,
                      timer: 3000,
                    })
                    .fire({
                      icon: "success",
                      title: "Invoice has been deleted.",
                    });
                  // this.$swal("Deleted!", "Invoice has been deleted.", "success");
                  this.getList();
                  this.$events.emit("customerProfileEvent");
                  this.$events.emit("CustomerMiniList");
                }
              })
              .catch((error) => {
                this.loadingDialog = false;
                this.$swal({
                  title: "Delete Invoice",
                  text: error.data.message,
                  type: "error",
                });
              });
          }
        });

      }
    },
  },
};
</script>
